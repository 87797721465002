[cmdk-dialog] {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 640px;
    width: 90vw;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(8px);
    border: 1px solid rgba(156, 156, 156, 1);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    color: white;
    border-radius: 20px;
    overflow: hidden;
    z-index: 9999;
  }
  
  [cmdk-overlay] {
    position: fixed;
    inset: 0;
    z-index: 60;
    background: rgba(12, 12, 12, 0.6);
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    backdrop-filter: blur(8px);
  }
  
  [cmdk-input] {
    width: 100%;
    padding: 12px 0px 12px 14px;
    font-size: 14px;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: white;
    backdrop-filter: blur(8px);
    margin-bottom: 12px;
    outline: none;
    border-bottom: 1px solid rgb(241 245 249 / 0.5);
  }
  
  [cmdk-input]::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  
  [cmdk-list] {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 12px;
    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer/Edge */
    scroll-padding-block-start: 8px;
    scroll-padding-block-end: 8px;
  }
  
  [cmdk-list]::-webkit-scrollbar {
    display: none;  /* Chrome, Safari, Opera */
  }
  
  [cmdk-item] {
    padding: 8px 14px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  [cmdk-item][data-selected="true"] {
    background: #474747;
  }
  
  [cmdk-group-heading] {
    padding: 8px 14px;
    color: #666;
    font-size: 14px;
    font-weight: 500;
  }

  [cmdk-empty] {
    padding: 8px 14px;
    color: #666;
    font-size: 14px;
    font-weight: 500;
  }

  [cmdk-loading] {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }